export const education = [
  {
    school: "General Assembly",
    dates: "March 2015 - August 2015",
    location: "New York, New York",
    concentration: "Web Development",
  },
  {
    school: "New York University",
    dates: "Septemeber 2009 - May 2013",
    location: "New York, New York",
    concentration: "Bachelor's in Psychology",
  },
];
