export const skills = [
  "Python",
  "Javascript",
  "Ruby",
  "PHP",
  " HTML/CSS",
  "MySQL",
  "PostgreSQL",
  "MongoDB",
  "Git",
  "Nodejs",
  "Rails",
  "Wordpress",
  " React/Redux",
  "Vue",
  "Angular",
  "Backbonejs",
  "jQuery",
  "SASS/SCSS",
  "Mocha",
  "Chai",
  "Jest",
  "JIRA",
  "Agile",
  "Responsive Design",
  "Figma",
  "Material UI",
  "Tailwind",
];

export const interests = [
  "Listens to podcasts about politics, technology, startup culture, music, and pop culture,",
  "Reads at least one nonfiction book a month and various fiction,",
  "Enjoys talking about the history of technology,",
  "A huge One Piece fan",
];
